import styled from '@emotion/styled';
import Link from 'next/link';
import { css } from 'theme-ui';

export const BoldAnchor = styled.a(
  css({
    color: 'inherit',
    textDecoration: 'inherit',
    fontSize: 3,
    cursor: 'pointer',
  }),
);

export const RegularAnchor = styled.a(
  css({
    color: 'inherit',
    textDecoration: 'inherit',
    cursor: 'pointer',
  }),
);

export const DynamicRegularAnchor = styled.a(
  css({
    color: 'inherit',
    textDecoration: 'inherit',
    cursor: 'pointer',

    '&:hover': {
      color: 'secondaryGreen',
    },
  }),
);

export const ClassicAnchor = styled.a(
  css({
    fontSize: '0.875rem',
    lineHeight: '135%',
    letterSpacing: '0.035em',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'white',

    '&:hover': {
      color: 'classyGrey',
    },
  }),
);

export const GoldenAnchor = styled(ClassicAnchor)(
  css({
    '&:hover': {
      color: 'focus',
    },
  }),
);

export const GreenAnchor = styled.a(
  css({
    textDecoration: 'none',
    color: 'secondaryGreen',
    cursor: 'pointer',

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
);

export const NoUnderlineAnchor = styled.a(
  css({
    textDecoration: 'none',

    '&:focus, &:hover, &:visited, &:link, &:active': {
      textDecoration: 'none',
      color: 'inherit',
    },
  }),
);

export const NoUnderlineAnchorBlock = styled(NoUnderlineAnchor)(
  css({
    width: '100%',
  }),
);

export const InvisibleAnchor = styled.a(
  css({
    display: 'block',
    position: 'relative',
    top: '-120px',
    visibility: 'hidden',
  }),
);

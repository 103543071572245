import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { getPage } from '../../api/wagtail';
import { FilterField } from '../../shared-styled/blocks/SearchAndFiltersBlocks';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import StaticImage from '../StaticImage';
import {
  selectStyles,
  FilterRemoveIcon,
  Label,
  Wrapper,
} from './SubcategoryFilter.styled';

const indicatorImage = '/img/dropdown-indicator.svg';

const DropdownIndicator = ({ children, ...props }) => (
  <components.DropdownIndicator {...props} css={{ marginRight: '8px' }}>
    <StaticImage
      width={9}
      height={5}
      alt='indicatorImage'
      src={indicatorImage}
      priority
    />
  </components.DropdownIndicator>
);

const SubcategoryFilter = ({ subcategories }) => {
  const {
    subcategoryFilterValue,
    setSubcategoryFilterValue,
    setIsSubcategoryFilterActive,
  } = useGlobalContext();

  const removeField = () => {
    setIsSubcategoryFilterActive(null);
    setSubcategoryFilterValue(null);
  };

  return (
    <Wrapper>
      <FilterField $isWrapped={true}>
        <Select
          options={subcategories}
          styles={selectStyles}
          value={subcategoryFilterValue}
          onChange={setSubcategoryFilterValue}
          isSearchable={false}
          components={{ DropdownIndicator }}
        />
        <Label>Filter by Subcategories</Label>
        <FilterRemoveIcon onClick={removeField} />
      </FilterField>
    </Wrapper>
  );
};

export default SubcategoryFilter;

import styled from '@emotion/styled';
import { css, Flex } from 'theme-ui';
import pxToRem from '../../utils/pixelsToRem';

export const HeaderCategoryNav = styled(Flex)(
  css({
    alignItems: 'center',
    gap: '25px',
  }),
);

export const NavItem = styled.a(({ $isDisabled = false, noArrow = false }) =>
  css({
    position: 'relative',
    fontSize: pxToRem(18),
    letterSpacing: '0.01em',
    color: !$isDisabled && 'secondaryGreen',
    textDecoration: 'none',
    textTransform: 'capitalize',

    '::after': {
      display: noArrow && 'none',
      position: 'absolute',
      pl: '7px',
      content: '">"',
      color: 'breadcrumbsSeparator',
    },

    '&:hover': {
      textDecoration: !$isDisabled && 'underline',
    },
  }),
);

export const PageTitle = styled.p(
  css({
    width: 'max-content',
    fontSize: pxToRem(18),

    '::first-letter': {
      textTransform: 'uppercase',
    },
  }),
);

import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useGlobalContext } from '../GlobalContextProvider/GlobalContextProvider';
import UniversalLink from '../UniversalLink/UniversalLink';
import {
  HeaderCategoryNav,
  NavItem,
  PageTitle,
} from './HeaderCategoryNavigation.styled';

const HeaderCategoryNavigation = ({ parentItm, title, opacity }) => {
  const router = useRouter();
  const { navItems } = useGlobalContext();

  if (router.asPath === '/_preview') {
    return null;
  }

  if (!navItems || !(navItems.length > 0)) {
    return null;
  }

  const isParentDisplayed =
    navItems[0].title !== 'News' && navItems[0].title !== 'Reports';
  const isChildDisplayed =
    navItems[0].title !== 'Militant Organizations' &&
    navItems[0].title !== 'Armed Forces' &&
    isParentDisplayed;

  const isParentTitleUsed =
    navItems[0].title === 'Armed Forces' || navItems[0].title === 'Weapons';
  const pageTitle = isParentTitleUsed
    ? parentItm.title || parentItm[0].titleWeaponName
    : title;

  const isMulticategory = !!parentItm && !!(parentItm.length > 1);

  return (
    <HeaderCategoryNav opacity={opacity}>
      <UniversalLink href={navItems[0].url} passHref>
        <NavItem>{navItems[0].title}</NavItem>
      </UniversalLink>
      {navItems[1] &&
        isParentDisplayed &&
        (!isChildDisplayed && isMulticategory ? (
          <NavItem $isDisabled={true}>{navItems[1].title}</NavItem>
        ) : (
          <UniversalLink href={navItems[1].url} passHref>
            <NavItem>{navItems[1].title}</NavItem>
          </UniversalLink>
        ))}
      {navItems[2] &&
        isChildDisplayed &&
        (isMulticategory ? (
          <div>
            {parentItm.map((subCategory, i) => (
              <UniversalLink
                key={subCategory.title}
                href={subCategory.url.split('/').slice(0, -1).join('/')}
                passHref
              >
                <NavItem noArrow={i !== parentItm.length - 1}>
                  {subCategory.titleCategory +
                    (i === parentItm.length - 1 ? '' : ', ')}
                </NavItem>
              </UniversalLink>
            ))}
          </div>
        ) : (
          <UniversalLink href={navItems[2].url} passHref>
            <NavItem>{navItems[2].title}</NavItem>
          </UniversalLink>
        ))}
      <PageTitle>{pageTitle}</PageTitle>
    </HeaderCategoryNav>
  );
};

export default HeaderCategoryNavigation;

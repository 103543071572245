import { useBreakpointIndex } from '@theme-ui/match-media';
import Link from 'next/link';
import React from 'react';
import {
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Grid } from 'theme-ui';
import UniversalLink from '../../components/UniversalLink/UniversalLink';
import { fullMobileNavigation, fullNavigation } from '../../consts/navigation';
import { MOBILE_BREAKPOINT_INDEX } from '../../consts/style-consts';
import 'react-accessible-accordion/dist/fancy-example.css';
import {
  ABOUT_US_URL,
  CONTACT_US_URL,
  DIRECTORY_URL,
  FAQ_URL,
  NEWS_LIST_URL,
  OUR_SIGHTS_URL,
  POLICY_URL,
  REPORTS_LIST_URL,
  TERMS_URL,
  WHATS_NEW_URL,
} from '../../consts/urls';
import {
  PseudoAccordionItem,
  StAccordion,
  StAccordionItem,
  StAccordionItemButton,
} from '../../shared-styled/blocks/AccordionBlocks';
import {
  GoldenAnchor,
  NoUnderlineAnchor,
} from '../../shared-styled/typography/Anchors';
import {
  NavigationPageContainer,
  NavigationColumns,
  NavColumn,
  StaticPageBlock,
  NavGroupTitle,
  NavSeparator,
  MobileAnchor,
} from './NavigationPage.styled';

const StaticPagesListComponent = () => (
  <Grid columns={['1fr 1fr', '1fr 1fr', '2fr']} mt={['12px']} gap={['7px']}>
    <UniversalLink href={WHATS_NEW_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>What&#39;s new</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
    <UniversalLink href={ABOUT_US_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>About us</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
    <UniversalLink href={CONTACT_US_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>Contact Us</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
    <UniversalLink href={DIRECTORY_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>Directory</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
    <UniversalLink href={OUR_SIGHTS_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>In Our Sights</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
    <UniversalLink href={POLICY_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>Privacy policy</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
    <UniversalLink href={FAQ_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>FAQ</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
    <UniversalLink href={TERMS_URL} passHref>
      <NoUnderlineAnchor>
        <StaticPageBlock>Terms and conditions</StaticPageBlock>
      </NoUnderlineAnchor>
    </UniversalLink>
  </Grid>
);

const NavigationPage = ({ isNotFoundPage }) => {
  const index = useBreakpointIndex();

  return (
    <NavigationPageContainer $isNotFoundPage={isNotFoundPage}>
      {index > MOBILE_BREAKPOINT_INDEX ? (
        <NavigationColumns>
          {fullNavigation.map((el) => (
            <NavColumn key={el.title}>
              <UniversalLink href={el.url} passHref>
                <NoUnderlineAnchor>
                  <NavGroupTitle>{el.title}</NavGroupTitle>
                </NoUnderlineAnchor>
              </UniversalLink>
              <NavSeparator />
              {el.items.map((el) => (
                <UniversalLink key={el.title} href={el.url} passHref>
                  <GoldenAnchor>{el.title}</GoldenAnchor>
                </UniversalLink>
              ))}
            </NavColumn>
          ))}
          <NavColumn>
            <UniversalLink href={NEWS_LIST_URL} passHref>
              <NoUnderlineAnchor>
                <NavGroupTitle>News</NavGroupTitle>
              </NoUnderlineAnchor>
            </UniversalLink>
            <UniversalLink href={REPORTS_LIST_URL} passHref>
              <NoUnderlineAnchor>
                <NavGroupTitle>Reports</NavGroupTitle>
              </NoUnderlineAnchor>
            </UniversalLink>
            <StaticPagesListComponent />
          </NavColumn>
        </NavigationColumns>
      ) : (
        <>
          <StAccordion allowZeroExpanded>
            {fullMobileNavigation.map((el) =>
              el.items.length > 0 ? (
                <StAccordionItem key={el.title}>
                  <AccordionItemHeading>
                    <StAccordionItemButton $uppercase>
                      {el.title}
                    </StAccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {el.items.map((el) => (
                      <UniversalLink key={el.title} href={el.url}>
                        <MobileAnchor>{el.title}</MobileAnchor>
                      </UniversalLink>
                    ))}
                  </AccordionItemPanel>
                </StAccordionItem>
              ) : (
                <UniversalLink key={el.title} href={el.url}>
                  <PseudoAccordionItem key={el.title}>
                    {el.title}
                  </PseudoAccordionItem>
                </UniversalLink>
              ),
            )}
          </StAccordion>
          <NavColumn>
            <StaticPagesListComponent />
          </NavColumn>
        </>
      )}
    </NavigationPageContainer>
  );
};

export default NavigationPage;
